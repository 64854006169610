
import { reactive, ref, onMounted } from 'vue'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/ProductEnums.ts";
import { useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default {
  name: 'CompositeAdd',
  setup(){

    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      store.dispatch(Actions.GET_PRODUCT_CATEGORIES);
      store.dispatch(Actions.GET_DEVICE_CLASSIFICATION);
      setCurrentPageBreadcrumbs("Products",[
        {title : "Products",to : "/products/list"},
        {title : "New Composite",to : "/products/composite/add"}
      ]);
    });


    const submitButton = ref<HTMLElement | null>(null);

    const success = ref(false)

    const errors = reactive({
      value : {}
    });

    const formData = reactive({
      product_type_id : 2,
      code : '',
      supplierCode : '',
      udi : '',
      product_category_id : null,
      device_classification_id : null,
      weight : null,
      obsolete : false,
      labelsPerPack : 1,
      cartonQty : 0,
      labelsPerCarton : 2,
      additionalLabels : 0,
      note : ''
    })


    const submitForm = () => {
      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.ADD_COMPOSITE,formData).then(() => {

        success.value = true;
        errors.value = [];
        submitButton.value?.removeAttribute("data-kt-indicator");
        window.scrollTo(0,0);
        setTimeout(function(){
          router.push('/products/list')
        },1000)

      }).catch((response) => {
        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })
    }

    return {
      store,
      submitButton,
      formData,
      submitForm,
      errors,
      success
    }
  }
}
